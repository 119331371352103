html {
  color: #000;
  background: #fff;
}

body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, input, textarea, p, blockquote, th, td {
  margin: 0;
  padding: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

fieldset, img {
  border: 0;
}

address, caption, cite, code, dfn, em, strong, th, var {
  font-style: normal;
  font-weight: normal;
}

ol, ul {
  list-style: none;
}

caption, th {
  text-align: left;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: normal;
}

q:before, q:after {
  content: "";
}

abbr, acronym {
  font-variant: normal;
  border: 0;
}

sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

input, textarea, select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  font-size: 100%;
}

legend {
  color: #000;
}

#yui3-css-stamp.cssreset {
  display: none;
}

html {
  box-sizing: border-box;
}

*, :after, :before {
  box-sizing: inherit;
}

html, body {
  font-family: Helvetica Neue, Arial, sans-serif;
  font-size: 62.5%;
}

#video {
  opacity: 0;
  width: 1px;
  height: 1px;
  position: absolute;
  bottom: 0;
  overflow: hidden;
}

div#location-indicator-light {
  width: -moz-fit-content;
  width: fit-content;
  padding: 2px 6px;
}

#audio-player-tutorial, #vs {
  align-items: center;
}

#verify-age-container, .button-container {
  align-items: column;
}

button:focus, .button:focus {
  outline-offset: 1px;
  outline: 2px solid #000;
}

.dark-mode button:focus, .dark-mode .button:focus {
  outline-color: #fff;
}

#verify-age-container button:focus {
  outline-color: #000;
}

#verify-age-container div:first-child button {
  color: #000;
}

.button-container button, .button-container .button, #toggle-mode, progress, body.dark-mode .custom-audio-player button, body.dark-mode {
  background-color: #001489;
}

html {
  color: #001489;
}

html, body, dialog, ::backdrop, .dark-mode button, .dark-mode .button, .dark-mode #toggle-mode, .custom-audio-player button, .dark-mode .custom-audio-player progress {
  background-color: #fff;
}

div#location-indicator-light.green, #verify-age-container div:first-child button {
  background-color: #52ff00;
}

div#location-indicator-light.red {
  background: red;
}

::-webkit-progress-bar {
  background-color: red;
}

::-webkit-progress-value {
  background-color: red;
}

::-moz-progress-bar {
  background-color: red;
}

#verify-age-container div:last-child button {
  background-color: red;
}

#halftime-message, .team, #time-period-clock {
  background-color: #d9d9d9;
}

#toggle-mode, .custom-audio-player button.tutorial-play, .custom-audio-player button.game-play {
  border: 3px solid red;
}

#toggle-mode, .custom-audio-player button {
  border-radius: 50%;
}

#halftime-message, .team, button, .button, #time-period-clock {
  border-radius: 12px;
}

progress {
  border-radius: 9px;
}

::-webkit-progress-bar {
  border-radius: 9px;
}

::-webkit-progress-value {
  border-radius: 9px;
}

::-moz-progress-bar {
  border-radius: 9px;
}

button, .button {
  box-shadow: none;
}

dialog, button, .button, progress {
  border: 0;
}

#verify-age-container div:last-child button, .button-container button, .button-container .button, .dark-mode {
  color: #fff;
}

.dark-mode button, .dark-mode .button, .dark-mode #time-period-clock td span, .dark-mode #halftime-message td span {
  color: #001489;
}

button, .button {
  cursor: pointer;
}

[x-cloak] {
  display: none !important;
}

#verify-age-container, .button-container, .custom-audio-player, #vs, button span {
  display: flex;
}

.button, .custom-audio-player button, #page, #toggle-mode, .team {
  display: grid;
}

#michelob-ultra-logo {
  display: block;
}

#verify-age-container, .button-container {
  flex-direction: column;
}

.points {
  font-size: 6.4rem;
}

dialog h3, #age-sorry h3 {
  color: #001489;
  font-size: 5.8rem;
}

h1, p, #halftime-message, #time-period-clock {
  font-size: 3.6rem;
}

button, .button, #vs, #box-score, #live-game-instructions p {
  font-size: 3.2rem;
}

div#location-indicator-light p {
  font-size: 1.2rem;
}

p, button, .button, h1, h3, #halftime-message, #time-period-clock, #box-score td, #box-score th, td.points {
  font-weight: 700;
}

#verify-age-container, #enter-buy-container {
  gap: 42px;
}

#audio-player-tutorial {
  justify-content: center;
  gap: 27px;
}

main button, .button {
  line-height: 1;
}

#screen-learn-the-game .button-container {
  margin-top: 72px;
}

#verify-age-container, #live-game-instructions, .button-container {
  margin-top: 54px;
}

#screen-learn-the-game h1, #game-status {
  margin-bottom: 48px;
}

#vs {
  margin: 0 15px;
}

.button-container .button, #audio-player-game button, #teams, #time-period-clock, #halftime-message {
  margin: 0 auto;
}

#page {
  min-height: 100vh;
}

button:not(#toggle-mode), .button {
  min-height: 78px;
}

#audio-player-tutorial button.tutorial-play {
  min-width: 48px;
  min-height: 48px;
}

#page {
  padding: 24px;
}

#container-points {
  padding: 15px 0;
}

#halftime-message td, #time-period-clock td {
  padding: 6px;
}

#box-score td, #box-score th {
  padding: 3px;
}

button {
  padding: 0;
}

.button, .custom-audio-player button, #page, #toggle-mode, .team {
  place-items: center;
}

#toggle-mode {
  position: absolute;
  right: 9px;
}

#page, #box-score th, #box-score td {
  text-align: center;
}

.button {
  text-decoration: none;
}

div#location-indicator-light p, button, .button {
  text-transform: uppercase;
}

#toggle-mode {
  top: 9px;
}

button {
  -webkit-appearance: none;
}

h1 span {
  white-space: nowrap;
}

button, .button {
  width: 195px;
}

progress, #box-score {
  width: 100%;
}

dialog {
  z-index: 1000;
  max-width: 87vw;
  padding: 20px;
}

#toggle-mode {
  width: 54px;
  height: 54px;
  padding: 0;
}

#audio-player-game button {
  width: 93px;
  height: 93px;
  margin-bottom: 27px;
}

#audio-player-tutorial button.tutorial-play {
  width: 111px;
  max-width: 111px;
  height: 111px;
  max-height: 111px;
}

progress {
  height: 18px;
}

.control-play {
  transform: translateX(9%);
}

.control-pause {
  transform: translateX(-12%);
}

.team {
  width: 126px;
  height: 126px;
  padding: 6px;
}

.team img {
  object-fit: contain;
  width: 100%;
}

#halftime-message, #time-period-clock {
  margin-top: 24px;
  margin-bottom: 36px;
}

#michelob-ultra-logo {
  margin: 54px auto;
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

#audio-player-tutorial button {
  min-width: 61px;
  max-width: 61px;
  min-height: 61px;
  max-height: 61px;
}

#audio-player-tutorial button img {
  width: 100%;
  height: 100%;
}

#audio-player-tutorial button.tutorial-play img {
  width: 47px;
  height: auto;
}

#audio-player-tutorial button.tutorial-play img.control-pause {
  width: 36px;
  height: 43px;
}

#screen-season-over p {
  max-width: 550px;
  font-size: 3rem;
}

#screen-game-ended p {
  max-width: 500px;
  font-size: 3rem;
}

.season-over-logos {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  display: flex;
}

#toggle-mode {
  appearance: none;
  background-color: #001489;
  background-image: url("mode-dark.386ae990.svg"), none;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 66%;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
}

.dark-mode #toggle-mode {
  background-color: #fff;
  background-image: url("mode-light.e7f74e61.svg"), none;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
}

/*# sourceMappingURL=index.0a1fa25d.css.map */
