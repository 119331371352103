/*
YUI 3.18.1 (build f7e7bcb)
Copyright 2014 Yahoo! Inc. All rights reserved.
Licensed under the BSD License.
http://yuilibrary.com/license/
*/

html{color:#000;background:#FFF}body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,code,form,fieldset,legend,input,textarea,p,blockquote,th,td{margin:0;padding:0}table{border-collapse:collapse;border-spacing:0}fieldset,img{border:0}address,caption,cite,code,dfn,em,strong,th,var{font-style:normal;font-weight:normal}ol,ul{list-style:none}caption,th{text-align:left}h1,h2,h3,h4,h5,h6{font-size:100%;font-weight:normal}q:before,q:after{content:''}abbr,acronym{border:0;font-variant:normal}sup{vertical-align:text-top}sub{vertical-align:text-bottom}input,textarea,select{font-family:inherit;font-size:inherit;font-weight:inherit;font-size:100%}legend{color:#000}#yui3-css-stamp.cssreset{display:none}

/* apply a natural box layout model to all elements, but allowing components to change */
html{box-sizing:border-box}*,:after,:before{box-sizing:inherit}

html,
body {
	font-family: "Helvetica Neue", Arial, sans-serif;
	font-size: 62.5%;
}

/* video#video {
	height: 0;
	max-width: 100%;
} */

#video {
	width: 1px;
	height: 1px;
	position: absolute;
	bottom: 0;
	opacity: 0;
	overflow: hidden;
}

div#location-indicator-light {
    width: fit-content;
    padding: 2px 6px;
}

#audio-player-tutorial,
#vs {
	align-items: center;
}

#verify-age-container,
.button-container {
	align-items: column;
}

button:focus, .button:focus {
    outline: 2px solid black;
    outline-offset: 1px;
}

.dark-mode button:focus, .dark-mode .button:focus {
    outline-color: white;
}

#verify-age-container button:focus {
	outline-color: black;
}

#verify-age-container div:first-child button {
    color: black;
}

.button-container button,
.button-container .button,
#toggle-mode,
progress,
body.dark-mode .custom-audio-player button,
body.dark-mode {
	background-color: #001489;
}

html {
	color: #001489;
}

html,
body,
dialog,
::backdrop,
.dark-mode button,
.dark-mode .button,
.dark-mode #toggle-mode,
.custom-audio-player button,
.dark-mode .custom-audio-player progress {
	background-color: #fff;
}

div#location-indicator-light.green,
#verify-age-container div:first-child button {
	background-color: #52FF00;
}

div#location-indicator-light.red {
    background: #f00;
}

::-webkit-progress-bar {
	background-color: #F00;
}

::-webkit-progress-value {
	background-color: #F00;
}

::-moz-progress-bar {
	background-color: #F00;
}

#verify-age-container div:last-child button {
	background-color: #F00;
}

#halftime-message,
.team,
#time-period-clock {
	background-color: #D9D9D9;
}

#toggle-mode,
.custom-audio-player button.tutorial-play,
.custom-audio-player button.game-play {
	border: 3px solid #f00;
}

#toggle-mode,
.custom-audio-player button {
	border-radius: 50%;
}

#halftime-message,
.team,
button,
.button,
#time-period-clock {
	border-radius: 12px;
}

progress,
::-webkit-progress-bar,
::-webkit-progress-value,
::-moz-progress-bar {
	border-radius: 9px;
}

button,
.button {
	box-shadow: none;
}

dialog,
button,
.button,
progress {
	border: 0 none;
}

#verify-age-container div:last-child button,
.button-container button,
.button-container .button,
.dark-mode {
	color: #fff;
}


.dark-mode button,
.dark-mode .button,
.dark-mode #time-period-clock td span,
.dark-mode #halftime-message td span {
	color: #001489
}

button,
.button {
	cursor: pointer;
}

[ x-cloak ] {
	display: none !important;
}

#verify-age-container,
.button-container,
.custom-audio-player,
#vs,
button span {
	display: flex;
}

.button,
.custom-audio-player button,
#page,
#toggle-mode,
.team {
	display: grid;
}

#michelob-ultra-logo {
	display: block;
}

#verify-age-container,
.button-container {
	flex-direction: column;
}

.points {
	font-size: 6.4rem;
}

dialog h3,
#age-sorry h3 {
	font-size: 5.8rem;
	color: #001489;
}

h1,
p,
#halftime-message,
#time-period-clock {
	font-size: 3.6rem;
}

button,
.button,
#vs,
#box-score,
#live-game-instructions p {
	font-size: 3.2rem;
}

div#location-indicator-light p {
	font-size: 1.2rem;
}

p,
button,
.button,
h1,
h3,
#halftime-message,
#time-period-clock,
#box-score td,
#box-score th,
td.points {
	font-weight: 700;
}

#verify-age-container,
#enter-buy-container {
	gap: 42px;
}

#audio-player-tutorial {
	gap: 27px;
}

#audio-player-tutorial {
    justify-content: center;
}

main button,
.button {
	line-height: 1;
}

#screen-learn-the-game .button-container {
	margin-top: 72px;
}

#verify-age-container,
#live-game-instructions,
.button-container {
	margin-top: 54px;
}

#screen-learn-the-game h1,
#game-status {
	margin-bottom: 48px;
}

#vs {
	margin: 0 15px;
}

.button-container .button,
#audio-player-game button,
#teams,
#time-period-clock,
#halftime-message {
	margin: 0 auto;
}

#page {
	min-height: 100vh;
}

button:not(#toggle-mode),
.button {
	min-height: 78px;
}

#audio-player-tutorial button.tutorial-play {
	min-height: 48px;
}

#audio-player-tutorial button.tutorial-play {
	min-width: 48px;
}

#page {
	padding: 24px;
}

#container-points {
	padding: 15px 0;
}

#halftime-message td,
#time-period-clock td {
	padding: 6px;
}

#box-score td,
#box-score th {
	padding: 3px;
}

button {
	padding: 0;
}

.button,
.custom-audio-player button,
#page,
#toggle-mode,
.team {
	place-items: center;
}

#toggle-mode {
	position: absolute;
}

#toggle-mode {
	right: 9px;
}

#page,
#box-score th,
#box-score td {
	text-align: center
}

.button {
	text-decoration: none;
}


div#location-indicator-light p,
button,
.button {
	text-transform: uppercase;
}

#toggle-mode {
	top: 9px;
}

button {
	-webkit-appearance: none;
}

h1 span {
	white-space: nowrap;
}

button,
.button {
	width: 195px;
}

progress,
#box-score {
	width: 100%;
}

dialog {
	padding: 20px;
	max-width: 87vw;
	z-index: 1000;
}

#toggle-mode {
	padding: 0;
	width: 54px;
	height: 54px;
}

#audio-player-game button {
	margin-bottom: 27px;
	width: 93px;
	height: 93px;
}

#audio-player-tutorial button.tutorial-play {
	max-width: 111px;
	max-height: 111px;
	width: 111px;
	height: 111px;
}

progress {
	height: 18px;
}

.control-play {
	transform: translateX(9%);
}

.control-pause {
	transform: translateX(-12%);
}

.team {
	width: 126px;
	height: 126px;
	padding: 6px;
}

.team img {
    width: 100%;
    /* height: 100%; */
    object-fit: contain;
}

#halftime-message,
#time-period-clock {
	margin-top: 24px;
	margin-bottom: 36px;
}

#michelob-ultra-logo {
	margin: 54px auto;
}

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0,0,0,0);
	border: 0;
}


#audio-player-tutorial button {
    min-width: 61px;
    min-height: 61px;
    max-width: 61px;
    max-height: 61px;
}

#audio-player-tutorial button img {
    width: 100%;
    height: 100%;
}

#audio-player-tutorial button.tutorial-play img {
    width: 47px;
    height: auto;
}

#audio-player-tutorial button.tutorial-play img.control-pause {
    width: 36px;
    height: 43px;
}

#screen-season-over p {
	font-size: 3rem;
	max-width: 550px;

}
#screen-game-ended p {
    font-size: 3rem;
    max-width: 500px;
}

.season-over-logos {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
}


#toggle-mode {
	appearance: none;
	background: url(../img/mode-dark.svg), #001489;
	background-repeat: no-repeat;
	background-position: center;
	background-size: 66%;
}

.dark-mode #toggle-mode {
	background: url(../img/mode-light.svg), #fff;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}